import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"


const GuestPostMain = () => {

  return (
    <section className="gpg-main main-cs-listing">
        <Container>
            <Row className="d-flex flex-column align-items-center">
                <Col md={8}>
                    <div className="header text-center">
                        <div className="ct-header"><Link to="/blog">Blog</Link></div>
                        <h1 className="title text-center">Guest Posting Guidelines</h1>
                        <p className="text-center ct-footer"><span>By Vishnu Kumar</span> . <span>November 17, 2022 </span></p>
                        <StaticImage src="../../images/guest-post-guidelines/Guest-post-guidelines-header.jpeg" alt="Guest Post Guideliness | WowMakers" placeholder="blurred" width={1200} height={678}/>
                    </div>
                    <p className="sub-desc">Thank you for your interest in contributing a guest blog for us.</p>
                    <p className="sub-desc">Please take your time to review the entire page to understand what kind of content we’re looking for, the guidelines, and how the submission process works.</p>
                    <h2>About us</h2>
                    <p className="sub-desc">We are all for the <strong>user experience</strong>. Every project that we have done has been laid on that foundation. We have designed many mobile apps and websites that have helped millions of people because of their great user experience and ease of use. </p>
                    <p className="sub-desc">In short, we play a major role in creating software products that will be essential to the growth of a business.</p>
                    <p className="sub-desc">We are also experienced in making explainer videos. The videos that we have created helped companies in grabbing people's attention, teach them something new, and turn them into paying customers.</p>
                    <h2>Our audience</h2>
                    <p className="sub-desc">Primarily, we would like to address people who are in search of insights/help to create better digital products, mobile/web apps, and websites.</p>
                    <p className="sub-desc">Also, we would like to share knowledge about the power of videos and visual storytelling to promote or explain their ideas/products/services/processes/stories, etc.</p>
                    <p className="sub-desc">For the same reason, our content will be directed at a diverse range of audiences. We wish to cater to the needs of <strong>“people”</strong> who are business owners, product managers, marketers, designers, etc.</p>
                    <h2>Why do we support guest posting?</h2>
                    <p className="sub-desc">We like the idea of turning our official WowMakers blog into a forum for knowledge exchange. By pooling content, we could expand our audience and give companies and individuals a chance to gain more visibility and quality backlinks—a mutually beneficial partnership.</p>
                    <h2>Content style</h2>
                    <p className="sub-desc">We are looking for something other than stuffy, formal blogs that would bore our audience. We favor writing that is informal and conversational. When it comes to style, we would prefer US English.</p>
                    <h2>Topics</h2>
                    <p className="sub-desc">Broadly, we fall under the niche of UX design and explainer videos. However, we would love to address subtopics such as UX design, web-mobile UX, UI design, product design, user research, user psychology, design thinking, usability, interaction design, explainer videos, video marketing, video production, etc.</p>
                    <p className="sub-desc">Interested parties can submit a wide range of topics that fall under these, and we will choose from the list.</p>
                    <h2>Backlinking</h2>
                    <p className="sub-desc">We are well aware of the fact that those who write for us are in search of a quality backlink. However, under any circumstances, we will not allow overstuffing of links. The maximum we could allow is 2-3 links per blog that are strategically placed. And we assure you that those links included will be do-follow ones.</p>
                    <h2>Outbound links</h2>
                    <p className="sub-desc">Is there another source with an excellent point of view on a statement? Do you want to ensure that people understand what a certain word or idea means? Add a link to it. Make sure that these links go to authoritative websites. But under one condition! Only in exceptional circumstances should an outbound link be used.</p>
                    <h2>Quality</h2>
                    <p className="sub-desc">We do not believe in quantity. Our prime focus is to deliver quality content. Once the content is rejected, there will always be room for resubmission. And the resubmission will only be approved if the suggestions by our editor have been taken care of. Also, stuffing words just for the sake of it will not be allowed under any circumstances.</p>
                    <h2>Images</h2>
                    <p className="sub-desc">Please include screenshots of images aligned with the source link if you have taken them from other sources. All the images, including the blog’s title card, will be designed by our in-house designers, as we are very particular about their standards.</p>
                    <p className="sub-desc">In the case of memes, one or two are acceptable; however, we cannot allow more than that.</p>
                    <h2>How to submit a guest blog post?</h2>
                    <p className="sub-desc">Send us a message explaining your proposal to info@wowmakers.com. We will soon get back to you. If we are on the same page, let’s cook up some good content. The publishing time from the moment you send us the content will be <strong>one week</strong>, provided it does not have any edits. However, if there are edits, you can expect your blogs to go live after a week of resubmitting.</p>
                    <h2>Publishing guidelines</h2>
                    <ul>
                        <li><p className="sub-desc">The article should be optimised in a search engine-friendly manner. If we feel like the piece falls short, we must be allowed to make the right changes.</p></li>

                        <li><p className="sub-desc">The content should be 100% plagiarism-free.</p></li>

                        <li><p className="sub-desc">As we have mentioned earlier, the maximum length we would prefer for blogs would be 1200 words. However, in some instances, this can be breached. until it is informed in advance</p></li>

                        <li><p className="sub-desc">Introduction decides the fate of any blog; introduce the topic crisply and conversationally. And most importantly, the intro should be at most 150 words.</p></li>

                        <li><p className="sub-desc">We expect original concepts, compelling arguments, and high-quality writing from extensive research.</p></li>

                        <li><p className="sub-desc">The information included in the report can’t be outdated or more than 2 years old. Unless the findings are still relevant,</p></li>

                        <li><p className="sub-desc">Use American punctuation and spelling.</p></li>

                        <li><p className="sub-desc">We will be adding links to at least 3-5 other blog posts (on our website) in your piece.</p></li>

                        <li><p className="sub-desc">Anything too promotional for your company or any other organisation will not be accepted.</p></li>

                        <li><p className="sub-desc">
                        Inaccurate information and offensive writing will not be accepted.
                        </p></li>

                        <li><p className="sub-desc">
                            Blogs should be highly readable. So each paragraph should be between three and five sentences.
                        </p></li>

                        <li><p className="sub-desc">
                            For an article to be published, it must meet our high standards of quality.
                        </p></li>

                        <li><p className="sub-desc">
                            We are against republishing the article elsewhere once it's approved on our end after submission.
                        </p></li>

                        <li><p className="sub-desc">
                            We reserve the right to remove or modify the article at any time.
                        </p></li>
                        <li><p className="sub-desc">
                            Anything excessively critical of people or businesses in the article shall be removed.
                        </p></li>                                                                                    
                    </ul>                        
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default GuestPostMain
