import React from 'react'
import SeoHead from '../../components/global/seoHead'
import Layout from '../../components/global/layout'
import Seo from '../../components/global/seo'
import ScrollIndicationHeightZero from '../../components/global/scrollIndicationHeightZero'
import GuestPostMain from '../../components/guest-post-guidelines/guestPostMain'
import DirectContact from '../../components/global/directContact'

import GuestPostFeraturedImage from "../../../static/guest-post-guidelines/featured-guest-post-guidelines.jpeg"

import "../../sass/pages/guest-post-guidelines.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={GuestPostFeraturedImage}
      title="Guest Posting Guidelines | WowMakers"
      description="This page lists all the guidelines that the content contributors need to strictly adhere to in guest posting"
    />
  </> 
)

const GuestPostGuidelines = () => {
    return(
        <Layout>
            <Seo
                bclass="guest-post-guidelines"
                bid="gpg"
             />
            <ScrollIndicationHeightZero />
            <GuestPostMain />
            <DirectContact />
        </Layout>
    )
}

export default GuestPostGuidelines